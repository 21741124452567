.topbarPanelContainer {
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topbarPanelItem {
    margin: 5px;
}

.topbarPanelItem:nth-of-type(3), .topbarPanelItem:nth-of-type(2) {
    position: relative;
}

.topbarPanelIcon {
    size: 15px;
    color: rgb(55, 55, 55);
    cursor: pointer;
}


.topbarPanelIcon:hover {
    color: #4b7d37;
}

.user {
    width: 270px;
    position: absolute;
    top: 25px;
    right: -38px;
    z-index: 100;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
    box-shadow: 0 0 12px 2px lightgray;
    z-index: 500;
    border-radius: 5px;
}

.cart {
    width: 280px;
    position: absolute;
    top: 25px;
    right: -10px;
    z-index: 100;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0 0 12px 2px lightgray;
    transition: all 0.3s ease-out;
    z-index: 500;
}

.topbarPanelItem:nth-of-type(3):hover .cart {
    opacity: 1;
    max-height: 375px;
}

.topbarPanelItem:nth-of-type(2):hover .user {
    opacity: 1;
    max-height: 375px;
}

.productCount {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #4b7d37;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
}

.count {
    font-size: 12px;
    color: white;
    font-weight: 700;
}