.navbarContainer {
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarItems {
    position: relative;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarItem {
    margin: 5px;
}

.navbarLink {
    display: block;
    text-decoration: none;
    color: rgb(55, 55, 55);
    font-size: 13px;
    font-weight: 500;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.navbarLink:hover {
    color: #4b7d37;
}

.navbarLink.active {
    color: #4b7d37;
}

.shop {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100vw - 100px);
    max-width: 1200px;
    display: none;
    z-index: 600;
}

.category {
    display: none;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100vw - 100px);
    z-index: 600;
}

.navbarItem:nth-child(2):hover .shop {
    display: block;
}

.navbarItem:nth-child(3):hover .category {
    display: flex;
}

@media (max-width: 850px) {
    .navbarContainer {
        margin-top: 20px;
    }
    
    .navbarItems {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbarLink {
        font-size: 18px;
        font-weight: 600;
    }

    .shop, .category {
        display: none;
    }

    .navbarItem:nth-child(2):hover .shop {
        display: none;
    }
    
    .navbarItem:nth-child(3):hover .category {
        display: none;
    }
}