.shopContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
}

.shopCategoryContainer {
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 15px 2px lightgray;
    gap: 10px;
}

.category {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.category:hover {
    transform: scale(1.1);
}

.imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
}

.headingContainer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading {
    color: black;
    font-size: 13px;
}

@media (max-width: 850px) {
    .shopContainer {
        width: 100%;
        height: 100%;
    }

    .shopCategoryContainer {
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        /* grid-template-rows: repeat(3, 0.2fr); */
        padding: 10px;
        border-radius: 0;
        box-shadow: unset;
    }

    .imgContainer {
        width: 100%;
        height: calc(100% - 20px);
    }

    .img {
        height: 100%;
    }

    .category:hover {
        transform: unset;
    }

    /* .category {
        width: 50%;
        min-width: 100px;
        margin: 10px auto;
    } */
}

/* @media (max-width: 550px) {
    .shopCategoryContainer {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
    }
} */