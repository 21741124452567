.display-products-main {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-products-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 5px;
}

.display-product {
    width: 90%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: start;
    gap: 20px;
    /* margin-top: 50px; */
}

.display-products-h3 {
    text-align: center;
}

@media (max-width: 950px) {
    .display-products-main {
        margin: 10px auto;
    }

    .display-product {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 767px) {
    .display-product {
        width: 100%;
        /* padding: 10px; */
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}
