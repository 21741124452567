.topbarContainer {
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 500;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fffffffa;
    box-shadow: 0 0 12px 1px lightgray;
}

@media (max-width: 850px) {
    .topbarItem:not(:last-child) {
        display: none;
    }
}