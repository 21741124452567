.categoryContainer {
    width: 95%;
    max-width: 1400px;
    margin: 30px auto;
    padding: 10px;
    box-sizing: border-box;
}

.category {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}

.category::-webkit-scrollbar {
    background-color: white;
    height: 4px;
}

.category::-webkit-scrollbar-thumb {
    background-color: #4b7d37;
    border-radius: 5px;
}

.categoryItem {
    text-decoration: none;
    /* padding: 15px; */
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    cursor: pointer;
    /* border-radius: 5px; */
    /* box-shadow: 9px 8px 20px 1px #d3d3d3c7; */
    overflow: hidden;
}

.categoryImgContainer {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
}

.categoryImg {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in;
}

.categoryItem:hover .categoryImg {
    transform: scale(1.1);
}

.categoryDetailsContainer {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoryTitle {
    font-size: 14px;
    font-weight: 400;
    color: rgb(0, 0, 0);
}

.slick-slide {
    padding: 15px;
    box-sizing: border-box;
}

.slick-slider .slick-dots {
    bottom: -15px;
}

.slick-dots li button:before {
    position: static!important;
    height: 10px!important;
    width: 10px!important;
    border-radius: 50%;
    background-color: #4b7d37;
    color: #4b7d37!important;
}

.slick-dots.slick-active {
    color: white!important;
}

@media (max-width: 1024px) {
    .categoryItem {
        width: 200px;
        min-width: 150px;
    }
}

@media (max-width: 950px) {
    .categoryContainer {
        margin: 10px auto;
    }
}

@media (max-width: 767px) {
    .categoryItem {
        width: 150px;
        min-width: 100px;
    }

    .categoryTitle {
        font-size: 12px;
    }

    .slick-slide {
        padding: 10px;
    }
}
