.removeBtnContainer {
    width: 100%;
    height: inherit;
    border: none;
    outline: none;
    background-color: inherit;
    color: inherit;
    border-radius: inherit;
    cursor: inherit;
    font-size: inherit;
}
