.searchResultContainer {
    width: 100%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.resultContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.staticText {
    font-size: 18px;
    margin-right: 5px;
}

.dynamicText {
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}

.displayProductContainer {
    width: 100%;
    min-height: 400px;
    max-height: 600px;
    overflow-y: scroll;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    place-items: center;
}

.displayProductContainer::-webkit-scrollbar {
    width: 7px;
    background-color: white;
}

.displayProductContainer::-webkit-scrollbar-thumb {
    /* width: 7px; */
    background-color: #4b7d37;
    border-radius: 5px;
}

@media (max-width: 950px) {
    .displayProductContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 767px) {
    .displayProductContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}
