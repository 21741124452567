.bannerImgContainer {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.imgContainer {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerContainer {
    width: 50%;
    padding: 40px;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
    align-items: start;
}

.headerItem {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerImgContainer {
    width: 100px;
    height: 100px;
}

.headerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerH3 {
    font-size: 0.9rem;
    text-align: center;
}

.headerP {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 900px) {
    .bannerImgContainer {
        flex-direction: column;
    }

    .imgContainer {
        width: 100%;
    }

    .headerContainer {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .headerContainer {
        gap: 10px;
        padding: 20px;
    }
}