@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;
  font-family: "Raleway", serif;
  font-optical-sizing: auto;
}
