.cartContainerMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 12px 1px lightgray;
}

.cartContainer {
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    max-height: 265px;
    overflow-y: scroll;
}

.cartContainer::-webkit-scrollbar {
    background-color: white;
    width: 8px;
}

.cartContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #4b7d37;
}

.displayCartItem {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.displayCartImgContainer {
    width: 48%;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayCartImg {
    width: 100%;
}

.displayCartDetailsContainer {
    width: 48%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.heading {
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
}

.subHeading {
    font-size: 13px;
}

.subHeading:nth-of-type(2) {
    font-weight: 600;
}

.removeBtn {
    width: 100%;
    height: 28px;
    margin-top: 5px;
    border: 1px solid #c70000;
    background-color: #c70000;
    border-radius: 2px;
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

.actionContainer {
    width: 100%;
    margin: 5px auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.actionBtn {
    text-decoration: none;
    height: 30px;
    width: 100%;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

.actionBtn:nth-of-type(1) {
    border: 1px solid #4b7d37;
    background-color: #4b7d37;
    color: white;
}

.actionBtn:nth-of-type(1):hover {
    background-color: #3b6a28;
}

.actionBtn:nth-of-type(2) {
    border: 1px solid #4b7d37;
    background-color: white;
    color: #4b7d37;
}

.actionBtn:nth-of-type(2):hover {
    background-color: #4b7d37;
    color: white;
}

.actionBtn:disabled {
    opacity: 0.6;
    cursor: default;
}

.actionBtn:nth-of-type(1):disabled:hover {
    background-color: #4b7d37;
}

.actionBtn:nth-of-type(2):disabled:hover {
    background-color: white;
    color: #4b7d37;
}

.defaultHeading {
    text-align: center;
}