.product {
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 12px 1px lightgray;
    border-radius: 3px;
}

.productImgContainer {
    position: relative;
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.productImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productDetailsContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productTitle {
    width: 100%;
    max-width: 200px;
    display: block;
    font-size: 15px;
    color: rgb(55, 55, 55);
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.productPrice {
    display: block;
    font-size: 0.9rem;
    color: rgb(55, 55, 55);
}

.discount {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #687a61;
    font-size: 12px;
    font-weight: 500;
    color: white;
}

.ratingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.colorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.color {
    height: 20px;
    width: 20px;
    border: 1px solid gray;
    border-radius: 2px;
    display: block;
    margin: 0 3px;
}

@media (max-width: 950px) {
    .productImgContainer {
        height: 200px;
    }
}

@media (max-width: 767px) {
    .productImgContainer {
        height: 280px;
    }
}

@media (max-width: 500px) {
    .discount {
        top: 5px;
        right: 5px;
    }

    .productTitle {
        font-size: 13px;
        max-width: 145px;
    }
}

@media (max-width: 450px) {
    .productDetailsContainer {
        padding: 5px;
    }

    .productImgContainer {
        height: 180px;
    }
}

@media (max-width: 350px) {
    .productImgContainer {
        height: 150px;
    }

    .productTitle {
        max-width: 90px;
    }
}