.searchInputContainer {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 3px;
    overflow: hidden;
}

.searchInput {
    width: calc(100% - 45px);
    height: 45px;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 15px;
    color: gray;
    letter-spacing: 3px;
}

.searchIconContainer {
    width: 44px;
    height: 45px;
    border-left: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchIcon {
    font-size: 22px;
    color: gray;
}