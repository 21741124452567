.defaultRouteContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
    max-width: 550px;
}