.banner-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-wrapper {
    width: 100%;
    /* max-width: 1200px; */
    height: 100%;
    filter: brightness(0.7);
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-header {
    position: absolute;
    bottom: 30%;
    left: 50%;
    width: 100%;
    max-width: 600px;
    transform: translate(-50%, 30%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-h2 {
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: white;
    text-align: center;
    margin: 5px;
}

.title {
    color: #43af17;
    font-weight: 500;
}

.banner-p {
    font-size: 0.9rem;
    font-weight: 500;
    color: white;
    text-align: center;
    margin: 5px;
}

.btn-group {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    position: relative;
    width: 200px;
    height: 40px;
    border: 1px solid #43af17;
    background-color: #43af17;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 10px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 0.4s ease;
}

/* .btn-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: white;
    z-index: 0;
    transition: width 0.5s ease;
}

.btn-text {
    z-index: 1;
    color: white;
} */

.btn:hover {
    border: 1px solid #43af17;
}

/* .btn:hover .btn-slider {
    width: 100%;
} */

@media (max-width: 650px) {
    .banner-header {
        padding: 10px;
        box-sizing: border-box;
    }

    .btn-group {
        flex-direction: column;
    }

    .btn {
        width: 100%;
    }

    .banner-h2 {
        font-size: 1.7rem;
        margin: 10px;
    }

    
}

/* .banner-container {
    width: 100%;
    height: 100vh;
    max-width: 1400px;
    min-height: 650px;
    max-height: 700px;
    margin: 35px auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    height: 100%;
}

.carousel-container {
    width: 100%;
}

.carousel-status {
    display: none;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px!important;
}

.banner-Item1-Container {
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#e37d00, #db7a03)
}

.banner-Item2-Inner {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-Item1-Inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(270deg, #7E8265 0%, #A0A38E 50%, #7E8265 100%);
}

.banner-Item-Inner-Details {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.banner-Item-Img-Container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.banner-Item-Img {
    width: 90%;
    box-shadow: 0 0 15px 5px #b55c00;
    border-radius: 10px;
}

.banner-Item-Inner-Details {
    width: 45%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.banner-Item-Header {
    position: relative;
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner-Item2-Container {
    width: 100%;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#494C3A, #5D6146);
}

.banner-Item3-Container {
    width: 100%;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#636262, #515151);
}

.banner-Item3-Inner {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(270deg, #373737 0%, #4e4e4e 50%, #373737 100%);
}

.banner-Item3-Main {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.banner-item3-side {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-Item3-Header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner3-side-imgs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner3-side-img-container {
    width: 35%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner3-side-img {
    width: 100%;
    opacity: 0;
}

.banner3-side-img.animate-banner3-side-img {
    animation: test6 0.5s ease-in-out forwards;
}

.banner3-main-img-container {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner3-main-img {
    width: 90%;
}

.banner-Item3-H2 {
    color: white;
    font-size: 25px;
    font-weight: 600;
    transform: translateX(-150%);
    opacity: 0;
}

.banner-Item3-H2.animate-banner-Item3-H2 {
    animation: test9 0.5s ease-in-out forwards;
}

.banner3-link-container {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner3-link {
    width: 95%;
    max-width: 400px;
    height: 40px;
    border: 1px solid #F9B572;
    background-color: #F9B572;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    transform: translateY(150%);
    opacity: 0;
}

.banner3-link.animate-banner3-link {
    animation: test10 0.7s ease-in-out forwards;
}

.banner-Item2-Header {
    position: relative;
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-Item-H2 {
    margin-right: 5px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    opacity: 0;
    transform: rotate(180deg);
}

.banner-Item2-H2 {
    margin: 0;
    margin-right: 5px;
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: left;
    opacity: 0;
    transform: translateX(-150%);
}

.banner-Item-H2.animate-H2 {
    animation: test1 0.4s ease forwards;
}

.banner-Item2-H2.item2-animate-H2 {
    animation: test5 0.4s ease-in-out forwards;
}

.banner-Item-H4 {
    margin: 10px 0;
    font-size: 25px;
    font-weight: 500;
    color: white;
    letter-spacing: 1px;
    text-align: left;
    opacity: 0;
    transform: translateX(200px);
}

#reverse-H4 {
    transform: translateX(-200px);
}

.banner-Item-H4.animate-H4 {
    animation: test2 0.5s ease-out forwards;
}

.additional-details {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.additional-lists {
    width: 100%;
    padding: 0;
    margin: 20px 10px;
    box-sizing: border-box;
    list-style: none;
    color: white;
}

.additional-list {
    margin: 3px 0;
    transform: translateX(-150%);
    opacity: 0;
}

.additional-list.animate-list {
    animation: test4 0.5s ease forwards;
}

.banner-Item-Btn {
    width: 90%;
    height: 40px;
    margin: 30px 0;
    border: 1px solid white;
    border-radius: 2px;
    background-color: white;
    color: #e37d00;
    cursor: pointer;
    opacity: 0;
    transform: translateY(100%);
}

.banner-Item-Btn.animate-btn {
    animation: test3 0.5s ease-in forwards;
}

.banner-Item2-Btn:hover {
    background-color: white;
    color: #65451F;
}


.banner-item2-side {
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannner-img2-side-img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-img2-side-img1 {
    width: 100%;
    opacity: 0;
}

.banner-img2-side-img1.animate-side-img1 {
    animation: test6 0.5s ease-out forwards;
}

.banner-img2-side-img2 {
    width: 100%;
    opacity: 1;
    transform: translateX(150%);
}

.banner-img2-side-img2.animate-side-img2 {
    animation: test7 0.5s ease forwards;
}

.banner-item2-main-img-container {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-item2-main-img {
    width: 100%;
}

.banner2-link {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 450px;
    height: 45px;
    border: 1px solid #F9B572;
    background-color: #f9b572;
    color: white;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    opacity: 0;
}

.banner2-link:hover {
    background-color: #fca956;
}

.banner2-link.animate-banner2-link {
    animation: test8 1s ease-in-out forwards ;
}


@keyframes test1 {
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}

@keyframes test2 {
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes test3 {
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes test4 {
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes test5 {
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes test6 {
    100% {
        opacity: 1;
    }
}

@keyframes test7 {
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes test8 {
    100% {
        opacity: 1;
    }
}

@keyframes test9 {
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes test10 {
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@media (max-width: 996px) {
    .banner-Item-Inner-Details {
        padding: 20px;
    }

    .banner-Item-H2, .banner-Item4-H2 {
        font-size: 30px;
    }
    
    .banner-Item-H4, .banner-Item4-H4 {
        font-size: 15px;
    }

    .banner-Item1-Inner, .banner-Item2-Inner, .banner-Item3-Inner, .banner-Item4-Inner {
        padding: 30px 0;
    }

    .banner3-side-img-container {
        width: 45%;
    }
}

@media (min-width: 767px) and (max-width: 1023px){
    .banner-Item-Img-Container {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .banner-container {
        height: 85vh;
        min-height: 630px;
        max-height: 630px;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        display: none;
    }

    .banner-Item-Inner-Details {
        padding: 10px;
    }

    .banner-Item2-Inner {
        padding: 0;
        flex-direction: column-reverse;
        align-items: center;
    }

    .banner-Item-Inner-Details {
        width: 100%;
        align-items: center;
    }

    .banner-Item-Header {
        justify-content: center;
    }

    .banner-Item-Img-Container {
        width: 70%;
        max-width: 320px;
    }

    .additional-details {
        display: none;
    }

    .banner-Item-Btn {
        width: 100%;
        height: 40px;
        margin: auto;
        margin-top: 20px;
    }
    
    .banner-Item1-Container, .banner-Item2-Container, .banner-Item3-Container, .banner-Item4-Container {
        padding: 40px 10px;
    }
    
    .banner-Item-H2 {
        font-size: 22px;
    }
    
    .banner-Item-H4, .banner-Item4-H4 {
        font-size: 14px;
    }

    .banner-Item1-Inner {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .banner-item2-side {
        margin: 10px 0;
        margin-left: 50px;
        width: 30%;
        max-width: 175px;
    }

    .banner-item2-main-img-container {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translate(0, -50%);
        width: 40%;
    }

    .banner-Item2-H2 {
        font-size: 15px;
    }

    .banner-img2-side-img2 {
        transform: translateX(-150%);
    }

    .banner-Item3-Main {
        flex-direction: column-reverse;
    }

    .banner-item3-side {
        width: 60%;
        padding: 10px;
    }

    .banner-Item3-Inner {
        flex-direction: column;
    }

    .banner3-side-imgs-container {
        margin: 10px auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .banner2-link {
        position: static;
        width: 100%;
        transform: unset;
        max-width: unset;
        top: unset;
        left: unset;
    }
}

@media (max-width: 550px) {
    .banner-Item2-Inner {
        position: relative;
    }

    .banner2-link {
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, -2%);
        width: 95%;
        height: 40px;
    }

    .banner3-main-img-container {
        width: 70%;
    }

    .banner-item3-side {
        width: 80%;
    }

    .banner3-link-container {
        margin: 10px auto;
    }
}

@media (max-width: 450px) {
    .banner-container {
        height: 70vh;
        min-height: 580px;
        max-height: 580px;
    }
}

@media (max-width: 667px) and (max-height: 375px) {
    .banner-container {
        height: 600px;
    }
} */