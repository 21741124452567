@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');

.footerMain {
    width: 100%;
    /* background-color: #1a1a1a; */
    background-color: #365629;
}

.topSection {
    width: 100%;
    padding: 30px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.topSectionContainer {
    width: 250px;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.topSectionContainerItem {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.headerP {
    margin: 5px 0 10px 0;
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: lightgray;
}

.headerH2 {
    font-size: 16px;
    font-family: 'Cormorant Garamond';
    color: #f7f7f7;
    text-align: left;
}

.topSectionLink {
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    color: lightgray;
    text-align: left;
    transition: all 0.3s ease-out;
    font-size: 13px;
}

.socialLink {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.socialLinkIcon {
    font-size: 30px;
    border-radius: 10px;
    margin-right: 20px;
    color: #ddd1c7;
    transition: all 0.3s ease-out;
}

.socialLinkIcon:hover, .topSectionLink:hover {
    transform: scale(1.1);
    color: white;
}

.bottomSection {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottomSectionItem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright {
    color: #f7f7f7;
}

.paymentImg {
    width: 90px;
    height: 40px;
    margin: 0 5px;
    background-color: white;
    padding: 5px;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 3px;
}

@media (max-width: 829px) {
    .topSection {
        flex-wrap: wrap;
    }   
}

@media (max-width: 829px) {
    .bottomSection {
        flex-flow: column-reverse;
    }
}

@media (max-width: 559px) {
    .topSectionContainer {
        width: 90%;
    }
}