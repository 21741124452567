.categoryMain {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 12px 2px lightgray;
    z-index: 100;
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    align-items: flex-start;
}

.arrowContainer {
    display: none;
    margin: 10px auto;
    justify-content: flex-start;
    align-items: center;
}

.arrow {
    font-size: 1.5rem;
    color: rgb(49, 49, 49);
}

.categories {
    width: 100%;
    text-decoration: none;
    margin: 10px auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
    border-radius: 5px;
    gap: 10px;
}

.categories:hover {
    transform: scale(1.1);
    box-shadow: 0 0 12px 2px lightgray;
}

.imgContainer {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
}

.headingContainer {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading {
    font-size: 1rem;
    color: black;
    text-align: center;
}

@media (max-width: 850px) {
    .categoryMain {
        top: 0;
        left: 100px;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 0;
        box-shadow: unset;
    }

    .wrapper {
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }
    
    .categories {
        width: 90%;
    }

    .imgContainer {
        width: 50%;
    }

    .arrowContainer {
        display: flex;
    }

    .imgContainer {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .wrapper {
        grid-template-columns: repeat(3, 1fr);
    }   
}