.userContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
}

.user {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userIconContainer {
    width: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userIcon {
    width: 100%;
}

.userDetailsContainer {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userDetailsHeading3 {
    margin: 10px 0;
    font-size: 16px;
}

.userDetailsLink {
    text-decoration: none;
    display: block;
    font-size: 13px;
    color: rgb(45, 45, 45);
    transition: all 0.2s ease;
}

.userDetailsLink:hover {
    font-weight: 600;
    color: #4b7d37;
}

.loginContainer {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.loginHeader {
    font-size: 13px;

}

.loginForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputContainer {
    width: 100%;
    border: 1px solid lightgray;
}

.input {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    padding-left: 5px;
    box-sizing: border-box;
    color: rgb(83, 83, 83);
}

.logoutBtn {
    width: 100%;
    height: 35px;
    border: none;
    outline: none;
    background-color: #E0115F;
    color: white;
    border-radius: 3px;
    margin: 10px auto;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

.logoutBtn:hover {
    background-color: #bc1756;
}