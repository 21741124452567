.loginBtnContainer {
    width: 100%;
    height: 35px;
    margin: 15px auto;
    cursor: pointer;
    background-color: #4b7d37;
    color: white;
    border-radius: 3px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    font-size: 13px;
    font-weight: 500;
}

.loginBtnContainer:disabled {
    cursor: default;
    opacity: 0.5;
}