.userTestingContainer {
    /* width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-image: url('../../Assets/Others/maintenance.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}