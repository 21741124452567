.barsContainer {
    display: none;
}

.bars {
    font-size: 20px;
}

@media (max-width: 850px) {
    .barsContainer {
        display: block;
    }
}